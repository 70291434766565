export const sendingMethods = [{
  id: 'parcel_locker',
  name: 'Nadanie w Paczkomacie',
  description: 'Nadam przesyłkę w Paczkomacie',
},
{
  id: 'pok',
  name: 'Nadanie w POK',
  description: 'Nadam przesyłkę w Punkcie Obsługi Klienta',
},
{
  id: 'courier_pok',
  name: 'Nadanie w POK obsługującym nadawanie przesyłek kurierskich',
  description: 'Nadam przesyłkę w Punkcie Obsługi Klienta',
},
{
  id: 'branch',
  name: 'Nadanie w Oddziale',
  description: 'Dostarczę przesyłkę do Oddziału InPost',
},
{
  id: 'dispatch_order',
  name: 'Odbiór przez Kuriera',
  description:
      'Utworzę zelecenie odbioru - przesyłkę odbierze Kurier InPost',
},
{
  id: 'pop',
  name: 'Nadanie w POP',
  description: 'Nadam przesyłkę w Punkcie Obsługi Przesyłek',
}];
